import React, { useState } from "react";
import axios from "axios";
import qs from "qs";
import "../styles/FormStyle.css";

function ApplyPage() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        age: "",
        gender: "",
        city: "",
        email: "",
        phone: "",
        referringMember: "",
        reason: "",
        instagramName: "",  // Added Instagram Name field
    });

    const [submitted, setSubmitted] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .post("/do_apply.php", qs.stringify(formData))
            .then((response) => {
                if (response.data === "Application submitted successfully.") {
                    setSubmitted(true);
                }
                console.log(response.data);
            })
            .catch((error) => {
                console.error("There was an error submitting the form!", error);
            });
    };

    return (
        <div>
            <h1>Membership Application</h1>
            {submitted ? (
                <p>Thank you for your Application.</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="firstName">First Name:</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="lastName">Last Name:</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="age">Age:</label>
                        <select
                            id="age"
                            name="age"
                            value={formData.age}
                            onChange={handleChange}
                            required>
                            <option value="" disabled>
                                Select Age
                            </option>
                            <option value="Under 18">Under 18</option>
                            {[...Array(83).keys()].map((age) => (
                                <option key={age + 18} value={age + 18}>
                                    {age + 18}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="gender">Gender:</label>
                        <input
                            type="text"
                            id="gender"
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="city">City:</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="phone">Phone Number:</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="instagramName">Instagram Name:</label>  {/* New Instagram Name field */}
                        <input
                            type="text"
                            id="instagramName"
                            name="instagramName"
                            value={formData.instagramName}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="referringMember">Referring Member (If Any):</label>
                        <input
                            type="text"
                            id="referringMember"
                            name="referringMember"
                            value={formData.referringMember}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="reason">Why Do You Want To Join?</label>
                        <textarea
                            id="reason"
                            name="reason"
                            value={formData.reason}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="inviteCode">Invite Code (If You Have One):</label>
                        <input
                            type="text"
                            id="inviteCode"
                            name="inviteCode"
                        />
                    </div>
                    <button type="submit">Submit Application</button>
                </form>
            )}
            <br/><br/><br/>
        </div>
    );
}

export default ApplyPage;
