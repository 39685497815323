import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HeaderComponent from "./components/HeaderComponent";
import ContainerComponent from "./components/ContainerComponent";
import FooterComponent from "./components/FooterComponent";
import HomePage from "./pages/HomePage"; // Assuming you have a HomePage component
import ApplyPage from "./pages/ApplyPage";
import LoginPage from "./pages/LoginPage";
import MembersMain from "./pages/MembersMain";
import InvitePage from "./pages/InvitePage";
import { AuthProvider } from "./context/AuthContext"; // Import AuthProvider

function App() {
	return (
		<AuthProvider>
			<Router>
				<HeaderComponent />
				<ContainerComponent>
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/invite" element={<InvitePage />} />
						<Route path="/apply" element={<ApplyPage />} />
						<Route path="/login" element={<LoginPage />} />
						<Route path="/memebrsmain" element={<MembersMain />} />
						{/* Add more routes as needed */}
					</Routes>
				</ContainerComponent>
				<FooterComponent />
			</Router>
		</AuthProvider>
	);
}

export default App;
