import React from "react";
import logo from "../images/logo400.webp";

function HomePage() {
	return (
		<div>
			<img src={logo} alt="logo" width="400" height="400" />
			<p className="mission-statement">
				The mission of the Black Sails Alliance is to cultivate a formidable
				community rooted in support, protection, and mutual growth. We are
				dedicated to fostering genuine connections and empowering each member
				through a network of friendship, collaboration, and shared knowledge. By
				uniting diverse talents and perspectives, we aim to create a safe harbor
				where all members can thrive personally and professionally, protected
				and propelled by our collective strength. We commit to supporting not
				just the characters our members portray but the individuals behind them,
				recognizing and nurturing their unique needs and aspirations.
			</p>
		</div>
	);
}

export default HomePage;
