import React, { useState } from "react";
import "../styles/InviteStyle.css";

const InvitePage = () => {
  const [response, setResponse] = useState(null);

  const handleDecline = () => {
    setResponse("declined");
  };

  const handleAccept = () => {
    window.location.href = "https://forms.gle/Nfar311SuMZnghbK6"; // Replace with your Google Form link
  };

  return (
    <div className="invite-page">
      <h1>Black Sails Alliance Invitation</h1>
      <div className="outline-placeholder">
        <p>
          <strong>Introduction:</strong>
          <hr /> The Black Sails Alliance is a unique alt social club and secret
          society that offers an unparalleled sense of community and belonging.
          Our members enjoy a range of exclusive benefits that set us apart from
          other groups.
        </p>
        <p>
          <strong>Benefits:</strong>
          <hr />
          <p>
            The Black Sails Alliance is more than just a social club—it's a
            sanctuary for those seeking a close-knit community and a sense of
            belonging. As a member, you'll have access to:
          </p>
          <ul>
            <li>
              Exclusive meetings and gatherings that bring our members together
              in a safe and welcoming environment.
            </li>
            <li>
              A dedicated Discord server where you can connect with fellow
              members anytime, ensuring you’re never alone.
            </li>
            <li>
              A network of trustworthy individuals who look out for each other,
              offering support and security in times of need.
            </li>
            <li>
              Opportunities to build lasting friendships and create memorable
              experiences together.
            </li>
            <li>
              A vetted and reliable group of people who are committed to
              fostering a supportive and inclusive community.
            </li>
          </ul>
          <p>
            Our members enjoy unique benefits that aren’t available to the
            general public, including special recognition and priority
            invitations to our events.
          </p>
        </p>
        <p>
          <strong>Membership Details:</strong>
          <hr /> All members are required to pay dues of $25 per month. This fee helps sustain the club and ensures we can offer high-quality experiences and opportunities. If you are unable to afford the fee, you may still accept the invite and be considered for membership under a council-approved fee waiver.
        </p>
        <p>
          <strong>Limited Slots:</strong>
          <hr /> We are accepting members in rounds, and this round has only 20 slots available. To be considered for membership, make sure to accept your invitation by clicking the button below.
        </p>
        <p>
          <strong>Important Information:</strong>
          <hr /> All applicants are treated with equal respect and fairness throughout the selection process. Acceptance into the Black Sails Alliance is determined by a unanimous vote of the Council, ensuring that every decision is made impartially. Rest assured, your application will remain confidential and will not be made public. Please note that personal connections do not influence the decision—no one has special privileges, and every applicant is evaluated solely on merit.
        </p>
      </div>
      {response === null && (
        <div className="button-container">
          
          <button className="accept-button" onClick={handleAccept}>Accept Invite</button>
        </div>
      )}
      {response === "declined" && (
        <div className="response-message">
          <p>Thank you for your response. We appreciate your consideration.</p>
        </div>
      )}
    </div>
  );
};

export default InvitePage;
