import React from 'react';
import { Link } from 'react-router-dom';

function HeaderComponent() {
    return (
        <header>
            <h1>Black Sails Alliance</h1>
            <nav>
                <div>
                 <Link to="/">Home</Link>
                </div>
                <div>
                    <Link to="/apply">Apply</Link>
                </div>
                <div>
                <Link to="/login">Members Login</Link> 
                </div>

            </nav>
        </header>
    );
}

export default HeaderComponent;
