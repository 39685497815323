import React from 'react';

function ContainerComponent({ children }) {
    return (
        <div className='main-container'>
            {children}
        </div>
    );
}

export default ContainerComponent;
