import React from 'react';

function FooterComponent() {
    return (
        <footer>
            <p>© 2024 Black Sails Alliance. All rights reserved.</p>
        </footer>
    );
}

export default FooterComponent;
