import React, { createContext, useState } from 'react';
import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = (username, password) => {
    return axios.post('http://localhost:8000/bsauth/login/', {
      username,
      password,
    })
    .then((response) => {
      setUser(response.data); // Assuming response data contains user info
      return response.data;
    })
    .catch((error) => {
      console.error('Login error:', error);
      throw error;
    });
  };

  const logout = () => {
    return axios.post('http://localhost:8000/bsauth/logout/', {})
    .then((response) => {
      setUser(null);
      return response.data;
    })
    .catch((error) => {
      console.error('Logout error:', error);
      throw error;
    });
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
