import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const MembersMain = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('http://localhost:8000/bsauth/verify-auth/', {
          withCredentials: true
        });
        setUser(response.data.user);
      } catch (error) {
        navigate('/login');
      }
    };
    checkAuth();
  }, [navigate]);

  if (!user) {
    return null; // or a loading spinner if you prefer
  }

  return (
    <div>
      <h1>Welcome, {user.username}</h1>
      {/* Add more member-specific content here */}
    </div>
  );
};

export default MembersMain;
